import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";
const Portfolio = ({ classicHeader, darkTheme }) => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();

  const filters = {
    DESIGN: "Design",
    //BRAND: "Brand",
    //PHOTOS: "Photos",
    PROGRAMMING: "Programming",
      XR: "XR",
      MANAGING: "Managing"
  };

  const projectsData = [
    {
      title: "Rat Raze",
      projectInfo:
        "This is a puzzel game, at the bottom you see a sliding puzzel and by sliding the pieces in the correct sequece you can drive the car over the finish line. In this project I first did the game design and after that I did all the programming. This was an AR project made for on a Tilt Five.",
      client: "Smart Games",
      technologies: "Unity",
      industry: "Design & Programming",
      date: "July 16, 2019",
      url: {
        name: " ", 
        link: "https://www.example.com",
      },
      //socialLinks: {
      //  facebook: "http://www.facebook.com/",
      //  twitter: "http://www.twitter.com/",
      //  google: "http://www.google.com/",
      //  instagram: "http://www.instagram.com/",
      //  mail: "mailto:example@gmail.com",
      //},
          thumbImage: "images/projects/RatRaze.jpg",
      sliderImages: [
        //"images/projects/RatRaze.jpg",
        "images/projects/RatRaze2.jpg",
          ],
          categories: [filters.DESIGN, filters.PROGRAMMING, filters.XR],
    },
    {
      title: "Laser Dodge",
      projectInfo:
            "In the game you are both the sniper and escaper. You need to snipe all the monster and obstacles so the runner can parkour to the end. But watch out for the lasers they can kill the runner to. In this project i did all the programming and found the assets online.",
      client: "School",
      technologies: "Unity",
      industry: "Design & Programming",
      date: "July 16, 2019",
      url: {
          name: "Youtube.com/LaserDodge",
          link: "https://youtu.be/ybvimFUrXOg",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:example@gmail.com",
      },
        thumbImage: "images/projects/LaserDodge1.jpg",
      sliderImages: [
        "images/projects/LaserDodge2.jpg",
        "images/projects/LaserDodge3.jpg",
        ],
        categories: [filters.DESIGN, filters.PROGRAMMING],
    },
    {
      title: "Frozen Monsters",
      projectInfo:
            "In this horror game you need to find all the levers to escape the lab. But watch out each lever you switch will unfreeze a monster that starts hunting. This is a project of mine in which i mostly programmed.I took models from the internet and try to make an good feeling horror game and after some play testers i think i succeeded.",
      client: "School",
      technologies: "Unity",
      industry: "Design & Programming",
      date: "July 16, 2019",
      url: {
        name: "Youtube.com/FrozenMonsters",
          link: "https://youtu.be/E0WNrE8Py2w",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:example@gmail.com",
      },
        thumbImage: "images/projects/FrozenMonsters1.jpg",
      sliderImages: [
        "images/projects/FrozenMonsters2.jpg",
        "images/projects/FrozenMonsters3.jpg",
      ],
        categories: [filters.DESIGN, filters.PROGRAMMING],
    },
    {
      title: "Yes Chef",
      projectInfo:
        "This was a group project were i took control over the project, On this project, there was only one game designer and that was me. So i designed the hole procces from what the players needs to do or what need to happen when a player did certained things. I also programmed some of the VR components, Like: holding pots, opening doors and all the VFX. The VFX were not easy because of the VR and Made the game lag. But in the end overcame all the problems and togheter with my team finnished the project. ",
      client: "IMEC",
      technologies: "Unity",
      industry: "Art & Design",
      date: "July 16, 2019",
      url: {
          name: "Youtube.com/YesChef",
          link: "https://youtu.be/NS42A__OrfY",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:example@gmail.com",
      },
      thumbImage: "images/projects/YesChef1.jpg",
      sliderImages: [
          
          "images/projects/YesChef2.jpg",
          "images/projects/YesChef3.jpg",
          "images/projects/YesChef4.jpg",
          "images/projects/YesChef5.jpg",
        ],
        categories: [filters.MANAGING, filters.DESIGN, filters.PROGRAMMING, filters.XR],
    },
    {
      title: "Lazer Puzzle",
      projectInfo:
        "This is a idea that came to me will at Smart Games. The idea is to have to play with 2 people and togheter you need to get to the top left of the board.",
      client: "Smart Games",
        technologies: "Unity",
      industry: "Art & Design",
      date: "July 16, 2019",
      url: {
          name: "Youtube.com/LazerPuzzle",
          link: "https://youtu.be/17EIpTQGR6U",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:example@gmail.com",
      },
        thumbImage: "images/projects/LaserPuzzle1.jpg",
      sliderImages: [
        "images/projects/LaserPuzzle2.jpg",
        "images/projects/LaserPuzzle3.jpg",
        "images/projects/LaserPuzzle4.jpg",
        ],
        categories: [filters.DESIGN, filters.PROGRAMMING],
    },
    {
      title: "Mexicoleccion",
      projectInfo:
        "This was a team project made for a museum in Mexico, they wanted a game for after the tour that would highlight some of the artifacts. So Me and the team made a sort of game with mini games the after completing an artifact unlocked",
        client: "3 Museos",
        technologies: "Unity",
      industry: "Art & Design",
      date: "July 16, 2019",
      url: {
          name: "Youtube.com/Mexicoleccion",
          link: "https://www.youtube.com/watch?v=5qtJLAQoptY",
      },
      socialLinks: {
        facebook: "http://www.facebook.com/",
        twitter: "http://www.twitter.com/",
        google: "http://www.google.com/",
        instagram: "http://www.instagram.com/",
        mail: "mailto:example@gmail.com",
      },
      thumbImage: "images/projects/mqdefault.jpg",
      sliderImages: [
        "images/projects/mq0.jpg",
        "images/projects/mq1.jpg",
           "images/projects/mq2.jpg",
          "images/projects/mq3.jpg",
        ],
        categories: [filters.DESIGN, filters.MANAGING],
    },
    
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, []);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  return (
    <>
      <section
        id="portfolio"
        className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
      >
        <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
          {/* Heading */}
          <div className="position-relative d-flex text-center mb-5">
            <h2
              className={
                "text-24  text-uppercase fw-600 w-100 mb-0 " +
                (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
              }
            >
              Portfolio
            </h2>
            <p
              className={
                "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
                (darkTheme ? "text-white" : "text-dark")
              }
            >
              {" "}
              My Work
              <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
            </p>
          </div>
          {/* Heading end*/}
          {/* Filter Menu */}
          <ul
            className={
              "portfolio-menu nav nav-tabs justify-content-center border-bottom-0 mb-5 " +
              (darkTheme ? "nav-light" : "")
            }
          >
            <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "*" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                All
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " +
                    (filterKey === filters[oneKey] ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(filters[oneKey])}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}
          <div className="portfolio popup-ajax-gallery">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      project.categories.join(" ")
                    }
                    key={index}
                  >
                    <div className="portfolio-box rounded">
                      <div className="portfolio-img rounded">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div className="portfolio-overlay">
                          <a
                            className="popup-ajax stretched-link"
                            href=""
                            onClick={() => {
                              setSelectedProjectDetails(projectsData[index]);
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          />
                          <div className="portfolio-overlay-details">
                            <h5 className="text-white fw-400">
                              {project.title}
                            </h5>
                            <span className="text-light">Category</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      <div className="project-details-modal">
        {/* Modal */}
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          darkTheme={darkTheme}
        ></ProjectDetailsModal>
      </div>
    </>
  );
};

export default Portfolio;
